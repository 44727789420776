.pageNotExists{
    color: whitesmoke;
    font-size: 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    font-style: italic;
}

.openHomePage{
    font-size: 32px;
    color: blue;
}