html, body {
    height: 100%;
    /* width: 80vw; */
    margin: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    /* font-family: "Courier New", Courier, monospace; Use a monospaced font */
}


.terminal {
    height: 80vh;
    width: 65vw;
    background-color: #1a45736c;
    /* background-color: #415a77; */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
}

.homepage{
    display: flex;
    justify-content: center;
}

.topbar {
    background-color: #0077b6;
    width: 100%;
    height: 30px;
    border-radius: 14px 14px 0px 0px;
    min-height: 30px;
    display: flex;
    justify-content: start;
    flex-direction: row;
}


.topbardots {
    height: 30px;
    border-radius: 14px 14px 0px 0px;
    min-height: 30px;
    display: flex;
    justify-content: start;
    flex-direction: row;
}

.topbardot1{
    background-color: #ff5f56;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin: 8px 0px 0px 24px ;
}
.topbardot2{
    background-color: #ffbd2e;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin: 8px 8px ;
}
.topbardot3{
    background-color: #27c93f;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin: 8px 0px ;
}

.welcomeText {
    line-height: 18px;
    white-space: pre;
    color: whitesmoke;
    font-size: 18px;
    max-width: 100%; 
    overflow: auto;
    text-align: center;
}

.main {
    width: 100%;
    align-self: flex-start;
    /* max-width: 100%; */
    overflow: auto;
    color: #00b4d8;
    font-weight: 700;
    font-size: 18px;
}
.command-list {
    margin-left: 2px;
    max-height: 100%; 
    overflow-y: auto;
    align-self: flex-start;
    max-width: 100%;
    width: 100%;
}

.notFound{
    color: wheat;
}

.gap{
    margin-bottom: 4px;
}
.gap-homepage-down{
    margin-bottom: 24px;
}
.gap-homepage-up{
    margin-bottom: 8px;
}

.hide{
    display: none;
}

.helpResult{
    color: whitesmoke;
    font-size: 19px;
    font-weight: 500;

}

.helpCommands{
    color: #a3f0ff;
    font-weight: 600;
}
.lsCommands{
    color: #1275ff;
    font-weight: 600;
    font-size: 19px;
}

.lsInstruction{
    font-size: 18px;
    color: whitesmoke;
    font-style: italic;
    font-weight: 500;
    margin-left: 12px;
}

@media screen and (max-width: 950px) {
    .terminal {
        height: 75vh;
        width: 95vw;
        background-color: #1a45736c;
        /* background-color: #415a77; */
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 14px;
    }
    .homepage{
        display: flex;
        justify-content: center;
    }
}
